/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Polyfills
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'stylesheets/application.css';
import '@fortawesome/fontawesome-free/js/all';

import React from 'react';
import ReactDOM from 'react-dom';

import { IntlProvider } from 'react-intl';
import messages from 'locales/messages';

import LoginPage from 'bundles/devise/components/LoginPage.jsx';

let locale =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage ||
  'pt-PT';

locale = locale.startsWith('en') ? "en" : locale
locale = messages[locale] ? locale : 'pt-PT'

const App = () => (
  <IntlProvider locale={locale} messages={messages[locale]}>
    <main className="bg-cinza-bg font-poppins h-screen w-full xl:mx-auto">
      <LoginPage />
    </main>
  </IntlProvider>
);

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(<App />, document.getElementById('root'));
});
