import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Checkbox from 'lib/Checkbox';
import ceve_login_logo_uri from '~/images/ceve-login-logo.png'
import ceve_logo_uri from '~/images/ceve-logo.png'

const LoginPage = () => {
  const intl = useIntl();
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="flex h-screen justify-center items-center">
      <div className="bg-gradient-to-b from-primary to-primary-dark rounded-l-lg rounded-r-lg flex flex-wrap mx-20 xl:mx-56 justify-center w-full">
        <div className="hidden rounded-xl lg:block lg:w-7/12">
          <img
            className="w-full h-full object-contain p-10 rounded-l-lg"
            src={ceve_login_logo_uri}
          />
        </div>

        <form
          className="bg-white shadow-xl flex flex-col rounded-lg justify-start content-start gap-y-5 w-full sm:w-2/3 lg:w-5/12 px-7 py-10"
          id="new_user"
          role="form"
          action="/users/sign_in"
          acceptCharset="UTF-8"
          method="post">
          <input name="utf8" type="hidden" value="✓" />
          <input
            type="hidden"
            name="authenticity_token"
            value={
              document.querySelector('[name=csrf-token]').content
            }
          />
          <input type="hidden" name="remember_me" value={isActive} />

          <img
            className="mx-auto w-40 object-scale-down"
            src={ ceve_logo_uri }
          />

          <p className="text-lg text-center text-black">
            <FormattedMessage id="loginPage.welcome" />
          </p>

          <input
            className="bg-gray-100 p-3 rounded-lg border-2 border-gray-300 text-xs outline-none"
            autoFocus="autofocus"
            placeholder="Email"
            type="email"
            name="user[email]"
            id="user_email"
          />
          <input
            className="bg-gray-100 p-3 rounded-lg border-2 border-gray-300 text-xs outline-none"
            placeholder={intl.formatMessage({
              id: 'loginPage.password',
            })}
            type="password"
            name="user[password]"
            id="user_password"
          />

          <input
            type="submit"
            name="commit"
            value={intl.formatMessage({ id: 'loginPage.enter' })}
            className="px-12 text-sm text-center mx-auto rounded-lg cursor-pointer"
            data-disable-with="Entrar"
          />

          <div className="flex items-center gap-2 justify-center">
            <Checkbox
              marginTop={0}
              active={isActive}
              onClick={setIsActive}
              size={5}
              padding={1}
            />
            <p className="text-xs text-gray-400">
              <FormattedMessage id="loginPage.remember_me" />
            </p>
          </div>

          <div className="bg-primary h-1 rounded-full" />

          <a
            href="/"
            className="text-center text-primary text-xs hidden">
            <FormattedMessage id="loginPage.forgotten_password" />
          </a>

          <p className="text-center text-sm text-gray-400 hidden">
            <FormattedMessage id="loginPage.new_user" />
            <a href="/" className="text-primary">
              <FormattedMessage id="loginPage.signup_here" />
            </a>
          </p>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
